import React from 'react';
import PropTypes from 'prop-types';
import './Todo-list-item.css';

class TodoListItem extends React.Component {
  render() {
    const {
      label,
      onDeleted,
      onToggleImportant,
      onToggleDone,
      important,
      done,
    } = this.props;

    return (
      <>
        <span
          onClick={onToggleDone}
          className={
            important
              ? done
                ? 'todo-list-item-active done'
                : 'todo-list-item-active'
              : done
              ? 'todo-list-item done'
              : 'todo-list-item'
          }
        >
          {label}
        </span>
        <div>
          <button onClick={onDeleted} className="btn btn-outline-danger">
            <i className="bi bi-trash-fill"></i>
          </button>
          <button
            onClick={onToggleImportant}
            className="btn btn-outline-success"
          >
            {important ? (
              <i className="bi bi-bookmark-check-fill"></i>
            ) : (
              <i className="bi bi-bookmark"></i>
            )}
          </button>
        </div>
      </>
    );
  }
}

TodoListItem.propTypes = {
  label: PropTypes.string,
  onDeleted: PropTypes.func,
  onToggleImportant: PropTypes.func,
  onToggleDone: PropTypes.func,
  important: PropTypes.bool,
  done: PropTypes.bool,
};

export default TodoListItem;
