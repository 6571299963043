import React from 'react';
import PropTypes from 'prop-types';
import './Item-add-form.css';

class ItemAddForm extends React.Component {
  state = {
    label: '',
  };

  onLabelChange = (e) => {
    this.setState({
      label: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onAdd(this.state.label);
    this.setState({
      label: '',
    });
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit} className="Item-add-form-parent">
          <input
            onChange={this.onLabelChange}
            className="form-control input"
            type="text"
            placeholder="Add something..."
            value={this.state.label}
          />
          <button type="submit" className="btn btn-outline-secondary button">
            Add
          </button>
        </form>
      </div>
    );
  }
}

ItemAddForm.propTypes = {
  onAdd: PropTypes.func,
};

export default ItemAddForm;
