import React from 'react';
import FilterPanel from '../Filter-panel/Filter-panel';
import SearchPanel from '../Search-panel/Search-panel';
import PropTypes from 'prop-types';
import './Panel.css';

class Panel extends React.Component {
  render() {
    const { onSearchChange, filter, onFilterChange } = this.props;

    return (
      <div className="Panel-parent">
        <SearchPanel onSearchChange={onSearchChange} />
        <FilterPanel filter={filter} onFilterChange={onFilterChange} />
      </div>
    );
  }
}

Panel.propTypes = {
  onFilterChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  filter: PropTypes.string,
};

export default Panel;
