import React from 'react';
import PropTypes from 'prop-types';
import TodoListItem from '../Todo-list-item/Todo-list-item';
import './Todo-list.css';

const TodoList = ({ todoData, onDeleted, onToggleImportant, onToggleDone }) => {
  const todos = todoData.map((item) => {
    const { id, ...otherProps } = item;
    return (
      <li className="todo-list-li list-group-item" key={id}>
        <TodoListItem
          {...otherProps}
          onDeleted={() => onDeleted(id)}
          onToggleImportant={() => onToggleImportant(id)}
          onToggleDone={() => onToggleDone(id)}
        />
      </li>
    );
  });
  return <ul className="list-group todo-list">{todos}</ul>;
};

TodoList.propTypes = {
  todoData: PropTypes.array,
  onDeleted: PropTypes.func,
  onToggleImportant: PropTypes.func,
  onToggleDone: PropTypes.func,
};

export default TodoList;
