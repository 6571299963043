import React from 'react';
import PropTypes from 'prop-types';
import './Filter-panel.css';

class FilterPanel extends React.Component {
  buttons = [
    { name: 'all', label: 'All', id: 1 },
    { name: 'active', label: 'Active', id: 2 },
    { name: 'done', label: 'Done', id: 3 },
  ];

  render() {
    const { filter, onFilterChange } = this.props;

    const btns = this.buttons.map(({ name, label, id }) => {
      const isActive = filter === name;
      const clazz = isActive
        ? 'Filter-btn btn btn-primary'
        : 'Filter-btn btn btn-outline-primary';
      return (
        <button
          key={id}
          type="button"
          className={`Filter-btn btn ${clazz}`}
          onClick={() => onFilterChange(name)}
        >
          {label}
        </button>
      );
    });

    return <div className="Filter-parent">{btns}</div>;
  }
}

FilterPanel.propTypes = {
  onFilterChange: PropTypes.func,
  filter: PropTypes.string,
};

export default FilterPanel;
