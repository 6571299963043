import React from 'react';
import PropTypes from 'prop-types';
import './App-header.css';

const AppHeader = ({ todo, done }) => {
  return (
    <div className="App-header-parent">
      <h1>Todo List</h1>
      <p className="text-muted">
        {todo} more to do, {done} done
      </p>
    </div>
  );
};

AppHeader.propTypes = {
  todo: PropTypes.number,
  done: PropTypes.number,
};

export default AppHeader;
