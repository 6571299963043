import React from 'react';
import PropTypes from 'prop-types';
import './Search-panel.css';

class SearchPanel extends React.Component {
  state = {
    term: '',
  };

  onSearchChange = (e) => {
    const term = e.target.value;
    this.setState({ term });
    this.props.onSearchChange(term);
  };

  render() {
    return (
      <input
        className="Search-panel-input form-control"
        placeholder="search..."
        value={this.state.term}
        onChange={this.onSearchChange}
      />
    );
  }
}

SearchPanel.propTypes = {
  onSearchChange: PropTypes.func,
};

export default SearchPanel;
